<template>
  <v-card class="ma-2" :elevation="0" color="grey-lighten-4" flat outlined>
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>VOTERS LIST</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <div class="row" v-if="is_mobile">
        <div class="col-6">
          <v-btn
            v-for="m in campaigns"
            size="mini"
            color="success"
            text
            :key="m.id"
            @click="campaign_changed(m)"
          >
            {{ m.name }}
          </v-btn>
        </div>
        <div class="col-6" style="text-align: right">
          <v-btn icon variant="text" @click="campaign_changed(campaigns[0])">
            <span class="mdi mdi-reload"></span>
          </v-btn>
          <v-btn icon variant="text" @click="show_filter = !show_filter">
            <span class="mdi mdi-filter-multiple-outline"></span>
          </v-btn>
        </div>
      </div>
      <el-form
        :rules="rules"
        ref="form"
        label-width="120px"
        label-position="top"
        class="demo-ruleForm"
        v-if="!is_mobile || show_filter"
      >
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Permanent Island" prop="islands_id">
              <select-list
                v-model="islands_id"
                :extraid="campaigns_id"
                @change="island_changed"
                :readonly="true"
                placeholder="Permanent Address Island"
                :list="islands"
                :customlist="true"
              />
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Current Island" prop="islands_id">
              <select-list
                v-model="current_islands_id"
                :extraid="campaigns_id"
                @change="current_island_changed"
                :readonly="true"
                placeholder="Current Address Island"
                :list="cislands"
                :customlist="true"
              />
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="House" prop="houses_id">
              <select-list
                v-model="houses_id"
                :extraid="islands_id"
                @change="house_changed"
                placeholder="Permanent Address House"
                :readonly="true"
                :list="houses"
                :customlist="true"
              />
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="ID Card No" prop="id_card_no">
              <el-input
                size="mini"
                v-model="id_card_no"
                @keyup.enter.native="searchByIdCard"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Search" prop="search">
              <el-input
                size="mini"
                v-model="search"
                @keyup.enter.native="searchByKeyword"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Ballot Box" prop="ballot_boxes_id">
              <select-list
                v-model="ballot_boxes_id"
                :extraid="campaigns_id"
                @change="ballot_box_changed"
                :customlist="true"
                :readonly="true"
                :list="ballotBoxes"
              />
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Constituency" prop="consituencys_id">
              <select-list
                v-model="consituencys_id"
                :extraid="campaigns_id"
                @change="consit_changed"
                :readonly="true"
                :list="constituencys"
                :customlist="true"
              />
            </el-form-item>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <el-form-item label="Status" prop="status_id">
              <select-list
                v-model="status_id"
                :extraid="campaigns_id"
                @change="status_changed"
                :readonly="true"
                :list="status"
                :customlist="true"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <v-data-table
        :headers="headers"
        :items="voters"
        elevation="0"
        v-if="is_mobile"
      >
        <template v-slot:item.photo="{ item }">
          <v-img
            :src="item.photo_data"
            width="100"
            max-width="100"
            aspect-ratio="1"
            class="grey lighten-2 mx-auto"
          >
            <template v-slot:placeholder>
              <v-img
                :src="no_img"
                width="100"
                max-width="100"
                aspect-ratio="1"
                class="grey lighten-2 mx-auto"
              />
            </template>
          </v-img>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
                <v-icon>mdi-form-dropdown</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title @click="updateVoterStatus(item, '13')">
                  Yes Vote
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="updateVoterStatus(item, '14')">
                  No Vote
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="updateVoterStatus(item, '16')">
                  Undecided
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-menu top :close-on-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      Will Not Vote
                    </v-list-item-title>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in cantVoteReasons"
                      :key="index"
                    >
                      <v-list-item-title
                        style="cursor: hand"
                        @click="updateCantVote(item, item.id)"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="lives_in_male(item)">
                  Male
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="lives_in_hulhumale(item)">
                  Hulhumale
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="changeNumber(item)">
                  Update Mobile
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="updateRemarks(item)">
                  Remaks/Require Transport
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Update Party</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="selectTeamMember(item)">
                  Team Member
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="requireMoney(item)">
                  Money
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="setCurrentAddress(item)">
                  Update Address
                </v-list-item-title>
              </v-list-item>
              <!--<v-list-item>
                <v-list-item-title @click="updateTransport(item)">
                  Require Transport
                </v-list-item-title>
              </v-list-item>-->
              <v-list-item>
                <v-list-item-title @click="updateScheduleDoorToDoor(item)">
                  Schedule D2D
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="postUpdateMetDoorToDoor(item)">
                  Met in D2D
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.full_name="{ item }">
          <span class="mdi mdi-id-card">
            {{ item.id_card_no }}
          </span>
          <el-alert
            v-if="showVoteStatus && !empty(item.status)"
            :title="item.status"
            style="padding: 3px"
            :type="
              item.status == 'Yes Vote'
                ? 'success'
                : item.status == 'No Vote'
                ? 'error'
                : 'warning'
            "
            effect="dark"
            :closable="false"
          ></el-alert>
          <el-alert
            v-if="showVoteStatus && !empty(item.other_candidate)"
            :title="item.other_candidate"
            style="padding: 3px; margin-top: 3px"
            :type="
              item.status == 'Yes Vote'
                ? 'success'
                : item.status == 'No Vote'
                ? 'warning'
                : 'warning'
            "
            effect="dark"
            :closable="false"
          ></el-alert>
          <br />
          <span
            :class="
              item.gender == 'Male'
                ? 'mdi mdi-human-male'
                : 'mdi mdi-human-female'
            "
          >
            {{ item.full_name }}
          </span>
          <br />
          <span class="mdi mdi-map-marker-outline">
            {{ item.current_address }}
          </span>
          <br />
          <span class="mdi mdi-map-marker">
            {{ item.permanent_address }}
          </span>
          <br />
          <span class="mdi mdi-cellphone-basic">
            <span>{{ item.mobile_number }}</span>
            <i
              v-if="item.mobile_verified == true"
              class="el-icon-success text--green"
            ></i>
          </span>
          <br />
          <span class="mdi mdi-ballot-outline"></span>
          <span>{{ item.ballot_box }} ({{ item.serial }})</span>
          <br />
          <v-chip
            class="ma-2"
            color="secondary"
            outlined
            v-if="!empty(item.responsible_person)"
          >
            {{ item.responsible_person }}
          </v-chip>
        </template>
      </v-data-table>
      <el-table
        :data="voters"
        v-if="!is_mobile"
        @sort-change="sortChange"
        empty-text="No Voters"
        :default-expand-all="true"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-button size="mini" @click="lives_in_male(props.row)">
              MALE
            </el-button>
            <el-button size="mini" @click="lives_in_hulhumale(props.row)">
              HULHUMALE
            </el-button>
            <el-button
              size="mini"
              type="success"
              @click="updateVoterStatus(props.row, '13')"
            >
              YES
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="updateVoterStatus(props.row, '14')"
            >
              NO
            </el-button>
            <el-button
              size="mini"
              type="warning"
              @click="updateVoterStatus(props.row, '16')"
            >
              UNDECIDED
            </el-button>
            <v-menu absolute offset-y style="max-width: 600px">
              <template v-slot:activator="{ on, attrs }">
                <el-button size="mini" type="danger" v-bind="attrs" v-on="on">
                  OPPONENT
                </el-button>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in otherCandidates"
                  :key="index"
                >
                  <v-list-item-title
                    style="cursor: hand"
                    @click="updateOtherCandidate(props.row, item.id)"
                  >
                    {{ item.full_name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <el-button size="mini" @click="updateRemarks(props.row)">
              REMARKS/TRANSPORT
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="changeNumber(props.row)"
            >
              MOBILE
            </el-button>
            <v-menu absolute offset-y style="max-width: 600px">
              <template v-slot:activator="{ on, attrs }">
                <el-button size="mini" type="danger" v-bind="attrs" v-on="on">
                  PARTY
                </el-button>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in politicalpartys"
                  :key="index"
                >
                  <v-list-item-title
                    style="cursor: hand"
                    @click="updatePoliticalParty(props.row, item.id)"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <el-button
              type="warning"
              size="mini"
              @click="selectTeamMember(props.row)"
            >
              TEAM MEMBER
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="requireMoney(props.row)"
            >
              MONEY
            </el-button>
            <el-button size="mini" @click="setCurrentAddress(props.row)">
              ADDRESS
            </el-button>
            <!--<el-button
              size="mini"
              type="success"
              @click="updateTransport(props.row)"
            >
              TRANSPORT
            </el-button>-->
            <v-menu absolute offset-y style="max-width: 600px">
              <template v-slot:activator="{ on, attrs }">
                <el-button size="mini" type="warning" v-bind="attrs" v-on="on">
                  JOB CLASSIFCATIONS
                </el-button>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in jobTitles" :key="index">
                  <v-list-item-title
                    style="cursor: hand"
                    @click="updateJobTitle(props.row, item.id)"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu absolute offset-y style="max-width: 600px">
              <template v-slot:activator="{ on, attrs }">
                <el-button size="mini" type="danger" v-bind="attrs" v-on="on">
                  WILL NOT VOTE
                </el-button>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in cantVoteReasons"
                  :key="index"
                >
                  <v-list-item-title
                    style="cursor: hand"
                    @click="updateCantVote(props.row, item.id)"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <el-button
              type="primary"
              v-if="empty(props.row.door_to_door)"
              size="mini"
              @click="updateScheduleDoorToDoor(props.row)"
            >
              SCHEDULE DOOR TO DOOR
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="postUpdateMetDoorToDoor(props.row)"
            >
              MET IN DOOR TO DOOR
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Photo" prop="photo" width="150">
          <template slot-scope="props">
            <object :data="props.row.photo_data" width="50px" type="image/png">
              <img
                class="rounded"
                :src="no_img"
                style="width: 50px; height: 50px"
                alt="Memeber Photo"
              />
            </object>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          label="ID Card No"
          prop="id_card_no"
          width="130"
        >
          <template slot-scope="scope">
            {{ scope.row.id_card_no }}
            <br />
            <el-alert
              v-if="showVoteStatus && !empty(scope.row.status)"
              :title="scope.row.status"
              style="padding: 3px"
              :type="
                scope.row.status == 'Yes Vote'
                  ? 'success'
                  : scope.row.status == 'No Vote'
                  ? 'error'
                  : 'warning'
              "
              effect="dark"
              :closable="false"
            ></el-alert>
            <el-alert
              v-if="showVoteStatus && !empty(scope.row.other_candidate)"
              :title="scope.row.other_candidate"
              style="padding: 3px; margin-top: 3px"
              :type="
                scope.row.status == 'Yes Vote'
                  ? 'success'
                  : scope.row.status == 'No Vote'
                  ? 'warning'
                  : 'warning'
              "
              effect="dark"
              :closable="false"
            ></el-alert>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          width="350px"
          label="Full Name"
          prop="full_name"
        >
          <template slot-scope="scope">
            {{ scope.row.full_name }} / {{ scope.row.gender }}
            <br />
            {{ scope.row.current_address }}
            <br />
            {{ scope.row.permanent_address }}
            <br />
            <el-alert
              :title="
                (!empty(scope.row.consit_code)
                  ? scope.row.consit_code + ' - '
                  : '') + scope.row.consit_name
              "
              type="warning"
              :closable="false"
              v-if="!empty(scope.row.consit_name)"
            ></el-alert>
            <v-chip
              class="ma-2"
              color="secondary"
              outlined
              v-if="!empty(scope.row.responsible_person)"
            >
              {{ scope.row.responsible_person }}
            </v-chip>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          width="120px"
          label="Mobile"
          prop="mobile_number"
        >
          <template slot-scope="props">
            <span>{{ props.row.mobile_number }}</span>
            <i
              v-if="props.row.mobile_verified == true"
              class="el-icon-success text--green"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          width="120px"
          label="House"
          prop="address"
        ></el-table-column>
        <el-table-column
          sortable
          width="120px"
          label="Ballot Box"
          prop="code"
        ></el-table-column>
        <el-table-column
          sortable
          width="250px"
          label="Ballot Box Name"
          prop="ballot_box"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChanged"
        @current-change="getVoters"
        :current-page.sync="currentPage"
        :page-sizes="[10, 25, 50, 100, 200, 300, 400]"
        :page-size="perPage"
        layout="total, prev, next, jumper"
        :total="total"
        class="mb-3 mt-3"
      ></el-pagination>
      <v-dialog
        v-model="showRemarksScreen"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">UPDATE REMAKRS/MEETINGS</h3>
              <v-spacer></v-spacer>
              <el-button
                size="mini"
                @click="
                  other_remarks = '';
                  showRemarksScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <el-input type="textarea" :rows="5" v-model="other_remarks" />
            </v-card-text>
            <v-card-actions>
              <div class="row">
                <div class="col-12">
                  <el-button size="mini" @click="updateDetails">
                    UPDATE
                  </el-button>
                  <el-button
                    size="mini"
                    class="pull-right"
                    @click="meetCandidate"
                  >
                    MEET CANDIDATE
                  </el-button>
                  <el-button size="mini" @click="meetLeadership">
                    MEET LEADERSHIP
                  </el-button>
                  <el-button size="mini" @click="door2door">
                    DOOR 2 DOOR
                  </el-button>
                  <el-button size="mini" @click="needHelp">
                    REQUIRE HELP
                  </el-button>
                  <el-button
                    size="mini"
                    type="success"
                    @click="updateTransport"
                  >
                    TRANSPORT
                  </el-button>
                </div>
              </div>
            </v-card-actions>
            <v-row>
              <v-col sm="12" lg="8">
                <member-control v-model="followup_id_card_no" />
              </v-col>
              <v-col sm="12" lg="4">
                <el-button size="mini" @click="postFollowup">
                  FOLLOW UP
                </el-button>
              </v-col>
            </v-row>

            <v-card-actions></v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <v-dialog
        v-model="showMobileScreen"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">UPDATE MOBILE NUMBER</h3>
              <v-spacer></v-spacer>
              <el-button
                size="mini"
                @click="
                  contactNumber = '';
                  showMobileScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <el-input-number
                :controls="false"
                :rows="5"
                v-model="contactNumber"
              />
            </v-card-text>
            <v-card-actions>
              <el-button size="mini" @click="postMobileNumber">
                UPDATE NUMBER
              </el-button>
              <el-button
                size="mini"
                type="danger"
                class="pull-right"
                @click="clearMobileNumbers"
              >
                ALL NUMBERS WRONG
              </el-button>
              <el-button
                size="mini"
                @click="
                  contactNumber = '';
                  showMobileScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <v-dialog
        v-model="showMoneyScreen"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">UPDATE MONEY VOTERS</h3>
              <v-spacer></v-spacer>
              <el-button
                size="mini"
                @click="
                  moneyAmount = 0;
                  showMoneyScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <el-input-number
                :controls="false"
                :rows="5"
                v-model="moneyAmount"
              />
            </v-card-text>
            <v-card-actions>
              <el-button size="mini" @click="postUpdateMoney">
                UPDATE MONEY REQUIREMENTS
              </el-button>
              <el-button
                size="mini"
                @click="
                  moneyAmount = 0;
                  showMoneyScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <v-dialog
        v-model="showCurrentAddressScreen"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">UPDATE CURRENT ADDRESS</h3>
              <v-spacer></v-spacer>
              <el-button size="mini" @click="closeCurrentAddress">
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <el-form
                :model="formCurrentAddress"
                :rules="rulesCurrentAddress"
                ref="formCurrentAddress"
                label-width="120px"
                label-position="top"
                class="demo-ruleForm"
              >
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <el-form-item label="Country" prop="current_countrys_id">
                      <select-list
                        v-model="formCurrentAddress.current_countrys_id"
                        :readonly="true"
                        editor="public/countrys"
                        custom-url="public/get-countrys"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <el-form-item label="Atoll" prop="current_atolls_id">
                      <select-list
                        v-model="formCurrentAddress.current_atolls_id"
                        :extraid="formCurrentAddress.current_countrys_id"
                        :readonly="true"
                        editor="membership/geo-classifications/atolls"
                        custom-url="public/get-atolls"
                      />
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <el-form-item label="Island" prop="current_islands_id">
                      <select-list
                        v-model="formCurrentAddress.current_islands_id"
                        :extraid="formCurrentAddress.current_atolls_id"
                        :readonly="true"
                        editor="membership/geo-classifications/islands"
                        custom-url="public/get-island-list"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <el-form-item label="House" prop="current_houses_id">
                      <select-list
                        v-model="formCurrentAddress.current_houses_id"
                        :extraid="formCurrentAddress.current_islands_id"
                        :readonly="true"
                        editor="membership/geo-classifications/houses"
                        custom-url="public/get-house-list"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <el-form-item
                      label="Apartment/Dhafthar/Flat No"
                      prop="dhafthar_no"
                    >
                      <el-input v-model="formCurrentAddress.dhafthar_no" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </v-card-text>
            <v-card-actions>
              <el-button size="mini" @click="postCurrentAddress">
                UPDATE CURRENT ADDRESS
              </el-button>
              <el-button size="mini" @click="closeCurrentAddress">
                CANCEL
              </el-button>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <v-dialog
        v-model="showScheduleDoorToDoor"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">SCHEDULE DOOR TO DOOR</h3>
              <v-spacer></v-spacer>
              <el-button
                size="mini"
                @click="
                  other_remarks = '';
                  door_to_door_date = null;
                  showScheduleDoorToDoor = false;
                "
              >
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <div class="block">
                <span class="demonstration">Scheduled Date/Time</span>
                <el-date-picker
                  v-model="door_to_door_date"
                  size="mini"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="Select date and time"
                ></el-date-picker>
              </div>
              <el-input
                type="textarea"
                class="mt-2"
                :rows="5"
                v-model="other_remarks"
              />
            </v-card-text>
            <v-card-actions>
              <el-button size="mini" @click="postUpdateScheduleDoorToDoor">
                UPDATE
              </el-button>
            </v-card-actions>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
      <v-dialog
        v-model="showTeamMemberScreen"
        persistent
        width="500px"
        transition="dialog-bottom-transition"
      >
        <v-container class="pa-0">
          <v-card class="pb-10">
            <v-system-bar height="47" color="#aeddff">
              <h3 class="black--text">SET TEAM MEMBER</h3>
              <v-spacer></v-spacer>
              <el-button
                size="mini"
                @click="
                  responsible_person_id_card_no = null;
                  showTeamMemberScreen = false;
                "
              >
                CANCEL
              </el-button>
            </v-system-bar>
            <v-divider />
            <v-card-text>
              <div class="block">
                <span class="demonstration">Responsible Person</span>
                <member-control v-model="responsible_person_id_card_no" />
              </div>
            </v-card-text>
            <v-card-actions>
              <el-button size="mini" @click="updateTeamMember()">
                UPDATE
              </el-button>
            </v-card-actions>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
    </v-card-text>
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from "vue";
import {
  Select,
  Option,
  Message,
  Table,
  TableColumn,
  Pagination,
  Button,
  Alert,
  Input,
  InputNumber,
  Form,
  FormItem,
} from "element-ui";
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Alert);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
import Swal from "sweetalert2";
Vue.prototype.$message = Message;
import SelectList from "@/components/custom/SelectList.vue";
import MemberControl from "@/components/custom/MemberControl.vue";
export default {
  created() {
    this.getCampaigns();
    if (this.campaigns.length == 1) {
      this.campaign_changed(this.campaigns[0]);
    }
  },
  components: {
    SelectList,
    MemberControl,
  },
  data() {
    return {
      valid: true,
      loading: false,
      show_filter: false,
      voters: [],
      campaigns_id: null,
      campaign_teams_id: null,
      ballot_boxes_id: null,
      consituencys_id: null,
      constituency: null,
      ballotBoxes: [],
      islands: [],
      cislands: [],
      houses: [],
      constituencys: [],
      status: [
        {
          id: 13,
          name: "Yes Vote",
        },
        {
          id: 14,
          name: "No Vote",
        },
        {
          id: 15,
          name: "Guaranteed Vote",
        },
        {
          id: 16,
          name: "Un Decided",
        },
        {
          id: 17,
          name: "Not Reached",
        },
        {
          id: 18,
          name: "Reached",
        },
        { id: 20, name: "Will Not Vote" },
      ],
      status_id: null,
      search: "",
      currentPage: 1,
      perPage: 25,
      total: 0,
      sort: "populations.full_name|asc",
      end_point: "public/",
      houses_id: "",
      islands_id: "",
      current_islands_id: null,
      id_card_no: "",
      view: "view",
      otherCandidates: [],
      jobTitles: [],
      teamMembers: [],
      campaigns: [],
      rules: {},
      selectedRow: null,
      showRemarksScreen: false,
      other_remarks: "",
      contactNumber: "",
      showMobileScreen: false,
      followup_id_card_no: "",
      politicalpartys: [],
      showMoneyScreen: false,
      history: [],
      showHistoryScreen: false,
      moneyAmount: 0,
      door_to_door_date: null,
      showScheduleDoorToDoor: false,
      responsible_person_id_card_no: null,
      showTeamMemberScreen: false,
      showCurrentAddressScreen: false,
      headers: [
        { text: "", value: "photo", width: 70 },
        { text: "", value: "full_name" },
      ],
      formCurrentAddress: {
        current_countrys_id: "",
        current_atolls_id: "",
        current_islands_id: "",
        current_houses_id: "",
        dhafthar_no: "",
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaigns_id: this.campaigns_id,
      },
      rulesCurrentAddress: {
        current_countrys_id: [
          {
            required: true,
            message: "Please select Current Country",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      cantVoteReasons: [
        { id: "Bedridden", name: "Bedridden" },
        { id: "Religios Reasons", name: "Religious Reasons" },
        { id: "No Good Candidate", name: "No Good Candidate" },
        { id: "Others", name: "Others" },
      ],
    };
  },
  methods: {
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getVoters();
        this.getPermissions();
        this.getJobTitles();
        this.getOtherCandidates();
        this.getPartys();
        this.getBallotBoxes();
        this.getIslands();
        this.getCurrentIslands();
        this.getConstituencys();
      }
    },
    searchByIdCard() {
      this.houses_id = "";
      this.search = "";
      this.getVoters();
    },
    searchByKeyword() {
      this.houses_id = "";
      this.id_card_no = "";
      this.getVoters();
    },
    ballot_box_changed(item) {
      if (!this.empty(item)) {
        this.ballot_boxes_id = item.id;
        this.getVoters();
      } else {
        this.ballot_boxes_id = null;
        this.getVoters();
      }
    },
    island_changed(item) {
      if (!this.empty(item)) {
        this.islands_id = item.id;
        this.getVoters();
        this.getHouses();
      } else {
        this.islands_id = null;
        this.getVoters();
      }
    },
    status_changed(item) {
      if (!this.empty(item)) {
        this.status_id = item.id;
        this.getVoters();
      } else {
        this.status_id = null;
      }
    },
    current_island_changed(item) {
      if (!this.empty(item)) {
        this.current_islands_id = item.id;
        this.getVoters();
      } else {
        this.current_islands_id = null;
        this.getVoters();
      }
    },
    house_changed(item) {
      if (!this.empty(item)) {
        this.houses_id = item.id;
        this.getVoters();
      } else {
        this.islands_id = null;
        this.getVoters();
      }
    },
    consit_changed(item) {
      if (!this.empty(item)) {
        this.constituency = item.name;
        this.getVoters();
      } else {
        this.constituency = null;
        this.getVoters();
      }
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getCampaignDetails();
    },
    fitlerData() {
      console.log(this.formFilter);
      this.currentPage = 1;
      this.getCampaignDetails();
    },
    sortChange(col) {
      this.sort = col.prop + "|" + (col.order == "ascending" ? "asc" : "desc");
      this.getCampaignDetails();
    },
    getVoters() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
        ballot_boxes_id: this.ballot_boxes_id,
        islands_id: this.islands_id,
        houses_id: this.houses_id,
        constituency: this.constituency,
        current_islands_id: this.current_islands_id,
        status_id: this.status_id,
      };
      this.api("public/voters", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.voters = response.data.data;
            this.total = response.data.total;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCampaigns() {
      this.loading = true;
      let f = {};
      this.api("public/campaigns", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.campaigns = response.data;
            console.log(this.campaigns);
            if (this.campaigns.length == 1) {
              this.campaign_changed(this.campaigns[0]);
            }
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPermissions() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-permissions", f)
        .then((response) => {
          this.loading = false;
          this.permissions = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getIslands() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-islands", f)
        .then((response) => {
          this.loading = false;
          this.islands = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCurrentIslands() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-current-islands", f)
        .then((response) => {
          this.loading = false;
          this.cislands = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHouses() {
      let f = {
        campaigns_id: this.campaigns_id,
        islands_id: this.islands_id,
      };
      this.api(this.end_point + "get-houses", f)
        .then((response) => {
          this.loading = false;
          this.houses = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getConstituencys() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-constituencys", f)
        .then((response) => {
          this.loading = false;
          this.constituencys = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOtherCandidates() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-other-candidates", f)
        .then((response) => {
          this.loading = false;
          this.otherCandidates = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBallotBoxes() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-ballot-boxes", f)
        .then((response) => {
          this.loading = false;
          this.ballotBoxes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamMembers() {
      let f = {
        campaigns_id: this.campaigns_id,
        campaign_teams_id: this.campaign_teams_id,
      };
      this.api(this.end_point + "get-team-members", f)
        .then((response) => {
          this.loading = false;
          this.teamMembers = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPartys() {
      this.api(this.end_point + "get-political-partys")
        .then((response) => {
          this.loading = false;
          this.politicalpartys = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getJobTitles() {
      this.api(this.end_point + "get-job-titles")
        .then((response) => {
          this.loading = false;
          this.jobTitles = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    lives_in_male(row) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: 48390,
        atolls_id: 2335,
        countrys_id: 1,
        voting_status: "",
      };
      this.callToApi(f, "lives-in-male");
    },
    lives_in_hulhumale(row) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: 48395,
        atolls_id: 2335,
        countrys_id: 1,
        voting_status: "",
      };
      this.callToApi(f, "lives-in-male");
    },
    updateVoterStatus(row, status) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        voting_status: status,
      };
      this.callToApi(f, "update-vote-status");
    },
    updateOtherCandidate(row, candidate_id) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        campaign_candidates_id: candidate_id,
        voting_status: 14,
      };
      this.callToApi(f, "update-other-candidate");
    },
    /*updateTeamMember(row, team_member_id) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: team_member_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        voting_status: 14,
      };
      this.callToApi(f, "update-team-member");
    },*/
    selectTeamMember(row) {
      this.selectedRow = row;
      this.responsible_person_id_card_no = null;
      this.showTeamMemberScreen = true;
    },
    updateTeamMember() {
      if (this.empty(this.responsible_person_id_card_no)) {
        this.showError("Select a Team Member");
        return;
      }
      let f = {
        campaign_voters_id: this.selectedRow.id,
        id_card_no: this.selectedRow.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        responsible_person_id_card_no: this.responsible_person_id_card_no,
        campaign_event_types_id: this.campaign_event_types_id,
      };
      this.callToApi(f, "update-team-member");
      this.showTeamMemberScreen = false;
    },
    updateJobTitle(row, job_id) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        job_titles_id: job_id,
        campaign_event_types_id: this.campaign_event_types_id,
      };
      this.callToApi(f, "update-job-title");
    },
    updateCantVote(row, reason) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        remarks: reason,
        voting_status: 20,
      };
      this.callToApi(f, "update-vote-status");
    },
    updatePoliticalParty(row, party_id) {
      let f = {
        campaign_voters_id: row.id,
        id_card_no: row.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        political_partys_id: party_id,
      };
      this.callToApi(f, "update-political-party");
    },
    updateScheduleDoorToDoor(row) {
      this.selectedRow = row;
      this.other_remarks = "";
      this.door_to_door_date = null;
      this.showScheduleDoorToDoor = true;
    },
    postUpdateScheduleDoorToDoor() {
      if (
        this.empty(this.door_to_door_date) &&
        this.empty(this.other_remarks)
      ) {
        this.showError("Select the Date/Time or enter Remarks");
      }
      let f = {
        campaign_voters_id: this.selectedRow.id,
        id_card_no: this.selectedRow.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        campaign_candidates_id: "",
        remarks: this.other_remarks,
        door_to_door_date: this.door_to_door_date,
      };
      this.callToApi(f, "update-door-to-door");
      this.other_remarks = "";
      this.door_to_door_date = null;
      this.showScheduleDoorToDoor = false;
    },
    postUpdateMetDoorToDoor(row) {
      Swal.fire({
        title: "Are you sure?",
        text: "This will mark the Door to Door Complete",
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: "Yes, Do it!",
      }).then((result) => {
        if (result.value) {
          let f = {
            campaign_voters_id: row.id,
            id_card_no: row.id_card_no,
            campaigns_id: this.campaigns_id,
            campaign_events_id: this.campaign_events_id,
            campaign_teams_id: this.campaign_teams_id,
            campaign_team_members_id: this.campaign_team_members_id,
            campaign_event_types_id: this.campaign_event_types_id,
            islands_id: "",
            campaign_candidates_id: "",
          };
          this.callToApi(f, "update-door-to-door-met");
        }
      });
    },
    changeNumber(row) {
      this.selectedRow = row;
      this.showMobileScreen = true;
      this.contactNumber = "";
    },
    clearMobileNumbers() {
      let f = {
        campaign_voters_id: this.selectedRow.id,
        id_card_no: this.selectedRow.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: !this.empty(this.data) ? this.data.id : null,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        campaign_candidates_id: "",
        remarks: this.other_remarks,
        followup: "",
        followup_type: "",
        voting_status: status,
      };
      this.callToApi(f, "clear-voter-mobile");
      this.showMobileScreen = false;
      this.contactNumber = "";
    },
    postMobileNumber() {
      let f = {
        campaign_voters_id: this.selectedRow.id,
        id_card_no: this.selectedRow.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        campaign_candidates_id: "",
        remarks: this.other_remarks,
        followup: "",
        followup_type: "",
        voting_status: "",
        mobile: this.contactNumber,
      };
      this.callToApi(f, "update-voter-mobile");
      this.showMobileScreen = false;
      let index = this.arrayGetIndex(this.data, this.selectedRow);
      if (index >= 0) {
        this.data[index].mobile_number = this.contactNumber;
        this.contactNumber = "";
      }
    },
    updateRemarks(row) {
      this.selectedRow = row;
      this.showRemarksScreen = true;
    },
    updateTransport() {
      //this.selectedRow = row;
      this.postRemarksToServer("", false, "transport");
      this.showMoneyScreen = false;
    },
    requireMoney(row) {
      this.moneyAmount = 0;
      this.selectedRow = row;
      this.showMoneyScreen = true;
    },
    postFollowup() {
      if (this.empty(this.other_remarks)) {
        this.showError("Enter details");
        return;
      }
      /*if (this.empty(this.followup_id_card_no)) {
        this.showError("Select the Member who will follow up");
        return;
      }*/
      this.postRemarksToServer(16, true, "followup");
    },
    updateDetails() {
      if (this.empty(this.other_remarks)) {
        this.showError("Enter details");
        return;
      }
      this.postRemarksToServer("", false, "team-member");
    },
    meetCandidate() {
      this.postRemarksToServer("", true, "candidate");
    },
    meetLeadership() {
      this.postRemarksToServer("", true, "leadership");
    },
    door2door() {
      this.postRemarksToServer("", true, "door2door");
    },
    needHelp() {
      if (this.empty(this.other_remarks)) {
        this.showError("Enter details");
        return;
      }
      this.postRemarksToServer("", true, "help");
    },
    setCurrentAddress(row) {
      this.selectedRow = row;
      this.formCurrentAddress = {
        current_countrys_id: row.current_countrys_id,
        current_atolls_id: row.current_atolls_id,
        current_islands_id: row.current_islands_id,
        current_houses_id: row.current_houses_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaigns_id: this.campaigns_id,
        id_card_no: row.id_card_no,
        campaign_voters_id: row.id,
      };
      this.showCurrentAddressScreen = true;
    },
    closeCurrentAddress() {
      this.formCurrentAddress = {
        current_countrys_id: "",
        current_atolls_id: "",
        current_islands_id: "",
        current_houses_id: "",
        current_dhafthar_no: "",
      };
      this.showCurrentAddressScreen = false;
    },
    postCurrentAddress() {
      this.$refs["formCurrentAddress"].validate((valid) => {
        if (valid) {
          this.callToApi(this.formCurrentAddress, "update-current-address");
          this.showCurrentAddressScreen = false;
        } else {
          return false;
        }
      });
    },
    postUpdateMoney() {
      /*if (this.empty(this.moneyAmount) || this.moneyAmount <= 0) {
        this.showError("Enter Estimated Amount");
        return;
      }*/
      this.other_remarks = this.moneyAmount;
      this.postRemarksToServer(16, false, "money");
      this.showMoneyScreen = false;
    },
    postRemarksToServer(status, followup, followup_type) {
      let f = {
        campaign_voters_id: this.selectedRow.id,
        id_card_no: this.selectedRow.id_card_no,
        campaigns_id: this.campaigns_id,
        campaign_events_id: this.campaign_events_id,
        campaign_teams_id: this.campaign_teams_id,
        campaign_team_members_id: this.campaign_team_members_id,
        campaign_event_types_id: this.campaign_event_types_id,
        islands_id: "",
        campaign_candidates_id: "",
        remarks: this.other_remarks,
        followup: followup,
        followup_type: followup_type,
        voting_status: status,
      };
      this.callToApi(f, "update-vote-status");
      this.showRemarksScreen = false;
      this.other_remarks = "";
    },
    callToApi(f, action) {
      this.loading = true;

      this.api(this.end_point + action, f)
        .then(() => {
          this.loading = false;
          //this.showInfo("Updated");
          this.$message.info("Voter Details updated");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Voters",
  computed: {
    is_mobile() {
      return (
        this.$vuetify.breakpoint.name == "sm " ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
    enterInteractions() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Enter Interactions")
            .length > 0
        : false;
    },
    exportExcel() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "xport Excel").length >
            0
        : false;
    },
    exportPDF() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Export PDF").length >
            0
        : false;
    },
    showContactNumbers() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Contact Numbers")
            .length > 0
        : false;
    },
    showPhotos() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Photos").length >
            0
        : false;
    },
    showVoteStatus() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Vote Status")
            .length > 0
        : false;
    },
    showDashboard() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Dashboard")
            .length > 0
        : false;
    },
    viewAllVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "View All Voters")
            .length > 0
        : false;
    },
    viewVPRegionVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter(
            (e) => e.permission == "View VP Region Voters"
          ).length > 0
        : false;
    },
  },
};
</script>
<style>
.v-list-item__title {
  cursor: pointer;
}

.v-data-footer {
  display: none;
}
</style>
