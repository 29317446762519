<template>
  <div>
    <v-row>
      <v-col md="5" sm="12">
        <v-img
          :src="photo"
          width="50"
          max-width="50"
          aspect-ratio="1"
          class="grey lighten-2 mx-auto"
        >
          <template v-slot:placeholder>
            <v-img :src="no_img" />
          </template>
        </v-img>
      </v-col>
      <v-col md="7" sm="12">
        <el-select
          v-model="selectedValue"
          :disabled="disabled"
          size="mini"
          filterable
          v-if="!disabled"
          remote
          :remote-method="searchMember"
          clearable
          :placeholder="getTitle(editor)"
          :name="editor"
        >
          <el-option
            v-for="item in listdata"
            :key="item.id_card_no"
            :label="item.name"
            :value="item.id_card_no"
          ></el-option>
        </el-select>
      </v-col>
      <v-col md="12" sm="12">
        <div style="margin-top: -30px">{{ id_card_no }}</div>
        <div style="margin-top: -25px">{{ name }}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Divider } from "element-ui";
Vue.use(Divider);
export default {
  name: "MemberControl",
  components: {},
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    members: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    editor: {
      type: String,
      default: "Select",
    },
    customUrl: {
      type: String,
      default: "",
    },
    atollsid: null,
    islandsid: null,
    view: null,
  },
  watch: {
    selectedValue: function () {
      if (!this.empty(this.selectedValue)) {
        let sel = this.listdata.filter(
          (e) => e.id_card_no == this.selectedValue
        );
        if (sel.length > 0) {
          this.id_card_no = sel[0].id_card_no;
          this.name = sel[0].name;
          this.address = sel[0].address;
          this.photo = sel[0].photo_data;
          let f = {
            id_card_no: this.id_card_no,
            name: this.name,
            address: this.address,
            photo: this.photo_data,
          };
          this.$emit("changed", f);
        } else {
          this.id_card_no = "";
          this.name = "";
          this.address = "";
          this.photo = "";
        }
      } else {
        this.id_card_no = "";
        this.name = "";
        this.address = "";
        this.photo = "";
      }
      this.$emit("input", this.selectedValue);
    },
    value: function () {
      console.log("value " + this.value);
      this.selectedValue = this.value;
      this.searchMember(this.value);
    },
    atollsid: function () {
      this.atolls_id = this.atollsid;
    },
    islandsid: function () {
      this.islands_id = this.islandsid;
    },
  },
  data: function () {
    return {
      selectedValue: null,
      atolls_id: "",
      islands_id: "",
      listdata: [],
      permissions: [],
      id_card_no: "",
      name: "",
      address: [],
      photo: "",
    };
  },
  mounted() {},
  methods: {
    getTitle(value) {
      if (this.empty(value)) return;
      let r = value.split("/");
      return this.toTitleCase(r[2]);
    },
    created() {
      //this.reload_list();
    },
    searchMember(value) {
      if (value == null || value.length != 7) return;
      let f = {
        id_card_no: value,
        islands_id: this.islands_id,
        atolls_id: this.atolls_id,
      };
      let api = "population/search-remote";
      if (this.members) {
        api = "population/search";
      }
      this.api(api, f)
        .then((response) => {
          if (response.status == true) {
            if (!this.empty(response.data) && response.data.length > 0) {
              this.id_card_no = response.data[0].id_card_no;
              this.name = response.data[0].name;
              this.address = response.data[0].address;
              this.photo = response.data[0].photo_data;
            }
            this.listdata = response.data;
            this.permissions = response.permissions;
            this.showForm = false;
          }
        })
        .catch(function (error) {
          if (error.response) {
            this.showError(error.response, "Member Control");
          } else {
            this.error(
              "Unknown errror, Contact System Administrator",
              "Member Control"
            );
          }
        });
    },
    close() {
      this.showForm = !this.showForm;
    },
  },
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
  },
};
</script>
<style scoped>
.is-empty {
  height: 0px !important;
}

.row {
  margin: 0px !important;
}
</style>
